import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Lottie from "react-lottie";
import ContactUsBlock from "../components/ContactUsBlock";
import Seo from "../components/seo";
import NavigateBlockV2 from "../components/NavigateBlockV2";
import ogIMage from "../images/og.png";
import { GatsbyImage } from "gatsby-plugin-image";

const SubServices = ({ data, pageContext, location }) => {
    let subservice = pageContext.subservice;
    let parentservice = pageContext.parentservice;
    // const clientLogo = data.pageData.services?.map((el) => {
    //     return el.clientsLogo?.map((e) => e);
    // });

    const schema = [
        {
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Sedin Technologies",
            url: "https://sedintechnologies.com/",
            sameAs: [
                "https://www.facebook.com/SedinTechnologies",
                "https://twitter.com/SedinTech",
                "https://www.instagram.com/lifeatsedin/",
                "https://www.linkedin.com/company/sedin-technologies/",
            ],

            address: {
                "@type": "PostalAddress",
                addressLocality: "Chennai",
                postalCode: "600083",
                streetAddress:
                    "# A1/1, 49th Street, 7th Avenue, Ashok Nagar, Chennai, TN, India, 600083",
            },
        },
        {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
                {
                    "@type": "ListItem",
                    position: 1,
                    name: "Home page",
                    item: "https://sedintechnologies.com/",
                },
                {
                    "@type": "ListItem",
                    position: 2,
                    name: "Services",
                    item: "https://sedintechnologies.com/services/",
                },
                {
                    "@type": "ListItem",
                    position: 3,
                    name: parentservice.title,
                    item: `https://sedintechnologies.com/services/${
                        location.pathname.split("/")[2]
                    }/`,
                },
                {
                    "@type": "ListItem",
                    position: 4,
                    name: data.pageData.name,
                    item: `https://sedintechnologies.com/services/${
                        location.pathname.split("/")[2]
                    }/${location.pathname.split("/")[3]}/`,
                },
            ],
        },
    ];

    let clientTestimonials = [];
    data.pageData.services.map((node) => {
        return node.divisions?.map((param) => {
            return param.testimonials?.map((testimonial) => {
                if (!testimonial.testimonialType) {
                    clientTestimonials.push(testimonial);
                }
            });
        });
    });
    const seoTitle = data.pageData.metatitle ? data.pageData.metatitle : data.pageData.name;
    const seoDescription = data.pageData.metadescription
        ? data.pageData.metadescription
        : data.pageData.description.description;

    const subServicesOgImage = data.pageData.ogImage?.gatsbyImageData?.images.fallback.src
        ? data.pageData.ogImage.gatsbyImageData?.images.fallback.src
        : ogIMage;

    return (
        <>
            <Layout>
                <Seo
                    title={seoTitle}
                    description={seoDescription}
                    schemaMarkup={schema}
                    url={location.href}
                    image={subServicesOgImage}
                />
                <section className="sub-services-hero">
                    <div className="container container--first-v2 ">
                        <div className="sub-services-hero__grid flex flex-jc-sb flex-ai-c">
                            <div className="sub-services-hero__text">
                                <div className="sub-services-hero__breadcrumbs gap-2x  text-fw-normal">
                                    <Link to={`/services`}>Services</Link>/
                                    <Link to={`/services/${parentservice.slug}/`}>
                                        {parentservice.title}
                                    </Link>
                                    /
                                    <Link
                                        to={`/services/${parentservice.slug}/${subservice.slug}/`}
                                    >
                                        {subservice.title}
                                    </Link>
                                </div>
                                <h1 className="text-h1 text-fw-medium text-clr-primary gap-2x">
                                    {data.pageData.name}
                                </h1>
                                <h3 className="text-p1 text-fw-regular gap-2x text-clr-secondary">
                                    {data.pageData.description.description}
                                </h3>
                                <div className="sub-services-hero__links gap-2x">
                                    {data.pageData.services.map((node) => (
                                        <span key={node.id}>
                                            {node.divisions?.map((param) => (
                                                <Link
                                                    className="sub-services-hero__link"
                                                    key={param.id}
                                                    to={`/divisions/${param.slug}/`}
                                                >
                                                    {param.name}
                                                </Link>
                                            ))}
                                        </span>
                                    ))}
                                </div>
                                <Link className="sdn-cta-btn" to="/contact">
                                    {data.pageData.buttonText}
                                </Link>
                            </div>
                            <div className="sub-services-hero__image">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        path: data.pageData.bannerAnimation?.file.url,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        <div className="sub-services-hero__clients">
                            {data.pageData.subServicesClients?.map((el, i) => (
                                <div className="sub-services-hero__clients-logo" key={i}>
                                    <GatsbyImage
                                        image={el.gatsbyImageData}
                                        alt={"img"}
                                        loading="lazy"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                {data.pageData.subservices && (
                    <div className="section-sub-services">
                        <div className="container container--mid-v2">
                            <div className="subservices-grid gap-3x">
                                {data.pageData.subservices?.map((node, i) => (
                                    <div className="subservices-item" key={node.id}>
                                        <div className="animation">
                                            <Lottie
                                                options={{
                                                    loop: true,
                                                    autoplay: true,
                                                    path: node.animation.file.url,
                                                    rendererSettings: {
                                                        preserveAspectRatio: "xMidYMid slice",
                                                    },
                                                }}
                                            />
                                        </div>
                                        <div className="content">
                                            <h3 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                                                {node.title}
                                            </h3>
                                            <p className="text-p3 text-fw-regular text-clr-secondary">
                                                {node.description.description}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}

                <div className="service-statistics-wrapper container--mid-v2">
                    <section className="service-statistics">
                        <div className="container container--py">
                            <div className="service-statistics__grid">
                                <div className="service-statistics__grid-text">
                                    <h2 className="text-h2 text-fw-medium text-clr-primary gap-2x">
                                        Statistics
                                    </h2>
                                </div>
                                <div className="service-statistics__grid-content">
                                    {data.pageData.statistics.map((node) => (
                                        <div
                                            key={node.id}
                                            className="service-statistics__grid-content-item"
                                        >
                                            <div className="service-statistics__grid-content-item__value">
                                                {node.value}
                                            </div>
                                            <div className="service-statistics__grid-content-item__name">
                                                {node.name}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {/* {clientTestimonials.length && (
                    <section className="sub-services-testimonials">
                        <div className="container  container--mid-v2">
                            {clientTestimonials.length > 0 && (
                                <Testimonials testimonialData={clientTestimonials} />
                            )}
                        </div>
                    </section>
                )} */}
                {data.pageData.ctaComponent ? (
                    <section className="navigate-cta">
                        <div className="container container--last-v2">
                            <NavigateBlockV2
                                button={data.pageData.ctaContent.map((e) => e.ctaText)}
                                title={data.pageData.ctaContent.map((e) => e.header)}
                            />
                        </div>
                    </section>
                ) : (
                    <section className="about-contact">
                        <div className="container container--last-v2">
                            <ContactUsBlock />
                        </div>
                    </section>
                )}
            </Layout>
        </>
    );
};

export default SubServices;

export const query = graphql`
    query subServicePageQuery($id: String) {
        pageData: contentfulServicesSolutionList(id: { eq: $id }) {
            id
            name
            title
            slug
            metatitle
            metadescription
            buttonText
            ogImage {
                gatsbyImageData(quality: 100)
            }

            subServicesClients {
                gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED, width: 176)
            }
            ctaComponent
            ctaContent {
                ctaText
                header
            }
            description {
                description
            }
            bannerAnimation {
                file {
                    url
                }
            }
            statistics {
                id
                name
                value
            }
            subservices {
                title
                slug
                id
                description {
                    description
                }
                animation {
                    file {
                        url
                        fileName
                        contentType
                    }
                }
            }
            services {
                id
                heading
                slug
                title
                statistics {
                    name
                    value
                    id
                }
                clientsLogo {
                    gatsbyImageData(
                        quality: 100
                        placeholder: BLURRED
                        layout: CONSTRAINED
                        width: 176
                    )
                }
                divisions {
                    id
                    name
                    slug
                    testimonials {
                        id
                        personDesignation
                        personName
                        profileImage {
                            gatsbyImageData(
                                quality: 100
                                sizes: "250"
                                layout: CONSTRAINED
                                placeholder: BLURRED
                            )
                            title
                        }
                        testimonialType
                        testimonialStatement {
                            testimonialStatement
                        }
                    }
                }
            }
        }
    }
`;
